<template>
	<page-container :page="page" :locale="locale" class="index">
		<div class="top-bar">
			<div class="align-left">
				<div v-if="page" class="language-navigation" @click="toggleLanguageMenu">
					<div v-if="langmenu.length" class="lang-select">
						<font-awesome-icon :icon="`fal fa-globe`" size="1x" />
						<span>{{ locale }}</span>
						<font-awesome-icon :icon="`fal fa-chevron-down`" size="1x" />
						<div class="lang-wrapper" :class="{ active: showLanguageMenu }">
							<div v-for="item in langmenu" :key="item.filename" class="language">
								<nuxt-link :class="item.language" :to="item.filename">
									<span>{{ item.language }}</span>
								</nuxt-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<section class="main-content intro">
			<div class="row" ref="headingImage">
				<div class="logo" />
				<div class="inner-row">
					<div class="columns column6 align-left">
						<div class="reverse-wrapper">
							<h1>{{ page.title || page.header }}</h1>
							<div v-parse-links v-html="page.subtitle" />
						</div>
						<nuxt-link class="scroll-down" to="#scrolldown">
							<img src="~assets/images/arrow-down-yellow.svg" alt="Scroll" />
						</nuxt-link>
					</div>
				</div>
				<div v-for="image in page.images" :key="image.ID" class="image">
					<picture>
						<source v-if="image.imageWebp" :srcset="image.imageWebp" type="image/webp" />
						<source :srcset="image.image" />
						<img class="object-fit" :src="image.image" :alt="image.imageAlt" />
					</picture>
				</div>
			</div>
			<div id="scrolldown" class="row">
				<div class="columns column8 align-left">
					<div v-parse-links v-html="page.content" />
				</div>
			</div>
		</section>

		<section class="main-content extra-content">
			<div class="row">
				<div class="columns column12 align-center">
					<div v-parse-links v-html="page.extraContent" />
				</div>
				<div class="columns column12 align-center">
					<div class="bottom-logo" />
				</div>
			</div>
		</section>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();

if (!defaults.value) {
	await fetchDefaults();
}

const showLanguageMenu = ref(false);

const langmenu = computed(() => {
	return (page.value && page.value.langmenu) ?? [];
});

const toggleLanguageMenu = () => {
	showLanguageMenu.value = !showLanguageMenu.value;
};
</script>

<style lang="scss" scoped>
.main-content {
	padding: 160px 0 80px;
}

.intro {
	background: url('~/assets/images/pattern-bg.png') repeat-x center top -90px #464f2c;
	padding: 0 0 170px;
	width: 100%;
	position: relative;
	overflow: hidden;
}

.image {
	border-radius: 50%;
	overflow: hidden;
	position: absolute;
	inset: 150px -75px auto auto;
	width: 900px;
	max-width: 80%;
	z-index: 1;
}

.extra-content {
	.row {
		max-width: 840px;
	}
}

.scroll-down {
	cursor: pointer;
	margin: 50px 0 0;
	display: inline-block;

	img {
		width: 34px;
	}
}

#scrolldown {
	margin-top: 50px;
	padding-top: 100px;
}

.reverse-wrapper {
	display: flex;
	flex-flow: column-reverse wrap;
}

.inner-row {
	position: relative;
	z-index: 2;
	min-height: 600px;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: center;
	width: 100%;

	.column6 {
		width: 58%;
	}
}

.logo {
	width: 264px;
	height: 264px;
	background: url('~/assets/images/logo-arq-hotels-full.png') no-repeat center center;
	background-size: 100%;
	margin: 0 auto;
	position: relative;
	z-index: 2;
	max-width: 60%;
}

.bottom-logo {
	width: 368px;
	height: 149px;
	background: url('~/assets/images/logo-arq-hotels-horizontal.png') no-repeat center center;
	background-size: 100%;
	margin: 40px auto 0;
	max-width: 80%;
}

.top-bar {
	width: 100%;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-end;
	align-items: center;
	position: absolute;
	inset: 8px 0 auto;
	z-index: 5;

	.align-right {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-end;
		align-items: center;
	}

	.align-left {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;
	}
}

.lang-select {
	padding: 8px 18px;
	margin: 0 18px 0 10px;
	position: relative;
	cursor: pointer;
	text-transform: uppercase;
	font-size: 13px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	color: #fff;

	span {
		margin: 2px 4px -2px;
	}

	a {
		color: #fff;
		display: flex;
		flex-flow: row nowrap;
		gap: 8px;
		margin: 7px 0;
		place-content: center flex-start;
		width: 100%;
		text-decoration: none;
	}

	svg {
		font-size: 12px;
	}

	.language {
		margin: 0;

		a.flag {
			margin: 2px 0;
			flex-flow: row nowrap;
			place-content: center flex-start;
			gap: 2px;
		}
	}
}

.lang-wrapper {
	display: none;
	position: absolute;
	top: 30px;
	left: 12px;
	padding: 10px 15px;
	text-align: center;
	background: #fff;
	box-shadow: 0 18px 18px rgb(0 0 0 / 10%);
	z-index: 90;
	border-radius: 10px;

	&.active,
	a {
		display: flex;
		flex-flow: column nowrap;
		place-content: space-between center;
		color: #000 !important;
		font-size: 13px;
		gap: 5px;
	}

	a {
		width: 100%;
		flex-direction: row;
	}

	img {
		width: 16px;
		height: 16px;
		margin: 2px 0 0;
	}
}

.lang-nav,
.lang-select {
	.language {
		.flag::before {
			content: '';
			width: 16px;
			position: relative;
			top: 2px;
			height: 16px;
			margin-right: 6px;
			display: inline-block;
		}

		.flag.en::before {
			background: url('~/assets/images/en.png') no-repeat;
		}

		.flag.nl::before {
			background: url('~/assets/images/nl.png') no-repeat;
		}

		.flag.de::before {
			background: url('~/assets/images/de.png') no-repeat;
		}

		.flag.fr::before {
			background: url('~/assets/images/fr.png') no-repeat;
		}

		.flag.es::before {
			background: url('~/assets/images/es.png') no-repeat;
		}
	}

	img {
		width: 16px;
		float: left;
		margin: 0 5px 0 0;
	}

	a {
		width: 100%;
		float: left;
		color: var(--body-color);
		text-decoration: none;
		margin: 4px 0;
		text-transform: uppercase;

		&.active {
			color: var(--cta-color);
		}
	}
}

.language-navigation .fa-globe {
	margin: 0 2px 0 0;
	color: var(--body-color);
	transition: color 0.3s ease-in-out;
}

@media (max-width: 1080px) {
	.intro {
		.column6,
		.column8 {
			width: 100%;
		}
	}

	.reverse-wrapper {
		width: 90%;
		max-width: 600px;
	}

	.image {
		max-width: 70%;
		inset: 300px -60px auto auto;
	}
}

@media (max-width: 880px) {
	.image {
		max-width: 75%;
		inset: 400px -110px auto auto;
	}

	.top-bar {
		flex-flow: row wrap;
		width: 100%;

		.column6 {
			width: 70%;
			justify-content: center;
			padding: 0 5px;

			&:first-child {
				width: 30%;
			}

			.socials,
			:deep(.socials) {
				justify-content: center;

				a {
					margin: 10px 5px 5px;
				}
			}
		}
	}
}

@media (max-width: 580px) {
	.image {
		max-width: 90%;
	}
}
</style>
